<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from "vue";
import {
  Layout as TLayout,
  Header as THeader,
  Aside as TAside,
  Content as TContent,
  HeadMenu as THeadMenu,
  Button as TButton,
  Icon as TIcon,
} from "tdesign-vue-next";
// @ts-expect-error 暂不处理
import { MyMenu } from "@myfront/tdesign-components";
import {
  useAppConfig,
  navigateTo,
  useRoute,
  useAuth,
  useRuntimeConfig,
  useRouter,
} from "#imports";
import type { Nav } from "~/app.config";

// 登录态相关信息和操作接口
const { data, signOut } = useAuth();

const route = useRoute();
const router = useRouter();

const runtimeConfig = useRuntimeConfig();

const config = useAppConfig();

const mainMenu = computed(() => {
  let [, moduleName = "pm"] = route.fullPath.split("/");
  // @ts-expect-error 暂不处理
  return config.mainMenu[moduleName];
});
const collapsed = ref(false);

const iconName = computed(() => (collapsed.value ? "chevron-right" : "chevron-left"));

const changeCollapsed = () => {
  collapsed.value = !collapsed.value;
};
const getWindowWidth = () => {
  console.log(window.innerWidth);
  // if (!collapsed.value) {
  if (window.innerWidth < 1220) {
    collapsed.value = true;
  } else {
    collapsed.value = false;
  }
  // }
};
// 监听窗口改变
onMounted(() => {
  window.addEventListener("resize", getWindowWidth);
});
//销毁
onUnmounted(() => {
  //移除监听事件
  window.removeEventListener("resize", getWindowWidth);
});

const clicked = (menu: Nav) => {
  console.log(menu);

  if (menu.value) {
    // let valueN = menu.value.replace(/\//g, "-").substring(1);
    let valueN = menu.value.split("/");
    console.log(valueN);
    // TODO
    navigateTo(menu.value);
    // navigateTo({
    //   name: "render-first_catalog-second_catalog",
    //   params: { first_catalog: valueN[2], second_catalog: valueN[3] },
    //   // params: { first_catalog: "1", second_catalog: "1" },
    // });
  }
};

const checkPath = (reg: RegExp, _index: number, _array: RegExp[]): boolean => {
  const regex = new RegExp(reg, "g");
  return regex.test(route.fullPath);
};

const currentMenu = computed(() => {
  if (mainMenu.value) {
    // const currentName = route.name;
    const currentName = route.fullPath;
    // 查找菜单
    let target: string = "";
    const findMenu = (menus: Nav[]) => {
      menus.map((menu) => {
        // if (menu.path === currentPath || (menu.meta.match && menu.meta.match.some(checkPath))) {
        if (
          // menu.name === currentName ||
          menu.meta.key === currentName
          // (String(currentName).startsWith(menu.name) && !menu.children)
        ) {
          // target = menu.name;
          target = menu.meta.key;
        }

        if (!target && menu.children) {
          findMenu(menu.children);
        }
      });
    };

    findMenu(mainMenu.value.navigation);
    return target;
  }
});

const logo_small = runtimeConfig.public.site.logo;

const contentWidth = computed(() =>
  collapsed.value ? "calc(100% - 64px)" : "calc(100% - 232px)"
);
const leftContentWidth = computed(() => (collapsed.value ? "64px" : "232px"));

const goHome = () => {
  navigateTo(runtimeConfig.public.site.defaultPortal);
};

const goto = (path: string) => {
  if (path === "data") {
    navigateTo({
      name: "bim-project_id-collection-model-list",
      params: { project_id: 1 },
    });
  } else if (path === "render") {
    navigateTo({
      name: "render-first_catalog-second_catalog",
      params: { first_catalog: "scene", second_catalog: "construct" },
      // params: { first_catalog: "1", second_catalog: "1" },
    });
  }
};
</script>
<template>
  <t-layout>
    <t-header style="position: sticky; top: 0; z-index: 99">
      <t-head-menu theme="dark" class="my-head-menu">
        <template #logo>
          <img v-if="!collapsed" width="20" class="logo cursor-pointer" :src="logo_small" alt="logo" @click="goHome" />
          <img v-if="collapsed" width="20" class="logo cursor-pointer" :src="logo_small" alt="logo" @click="goHome" />
          <span class="text-xl text-white cursor-pointer" @click="goHome" style="
              font-size: 20px;
              font-weight: 700;
              color: rgb(222, 222, 222);
              margin-left: 10px;
            ">{{ runtimeConfig.public.site.name }}</span>
          <!-- <t-button
            class="t-demo-collapse-btn"
            variant="text"
            shape="square"
            @click="changeCollapsed"
          >
            <template #icon>
              <t-icon
                name="view-list"
                color="white"
              />
            </template>
          </t-button> -->
        </template>
        <t-menu-item value="data" @click="goto('data')"> 数据引擎 </t-menu-item>
        <t-menu-item value="render" @click="goto('render')"> 渲染引擎 </t-menu-item>
        <template #operations>
          <a href="javascript:;"><t-icon class="t-menu__operations-icon" name="search" /></a>
          <a href="javascript:;"><t-icon class="t-menu__operations-icon" name="notification-filled" /></a>

          <t-dropdown trigger="hover" :options="[
            {
              content: data.user.name,
              value: 1,
              onClick: () => { },
            },
            {
              content: '退出',
              value: 1,
              onClick: () => signOut(),
            },
          ]" :min-column-width="88">
            <a href="javascript:;">
              <t-icon class="t-menu__operations-icon" name="user" />
            </a>
          </t-dropdown>
        </template>
      </t-head-menu>
    </t-header>
    <t-layout>
      <div style="">
        <t-aside class="menu-left" :style="{
          position: 'fixed',
          borderTop: '1px solid var(--component-border)',
          width: `${leftContentWidth}`,
          zIndex: '99',
        }">
          <MyMenu :keys="{ value: 'key' }" :value="currentMenu" :expanded="mainMenu.expanded" theme="dark" :style="{
            height: 'calc(100vh - 56px)',
            '--td-font-white-2': 'rgb(193, 198, 200)',
          }" :collapsed="collapsed" :menu="mainMenu.navigation" @clicked="clicked">
            <template #operations>
              <t-button class="t-demo-collapse-btn" variant="text" shape="square" @click="changeCollapsed">
                <template #icon>
                  <t-icon color="rgb(193, 198, 200)" :name="iconName" />
                </template>
              </t-button>
            </template>
          </MyMenu>
        </t-aside>
      </div>

      <t-layout class="w-full">
        <t-content>
          <div class="outer-scrollbar" :style="{
            height: 'calc(100vh - 56px)',
            overflow: 'auto',
            width: '100%',
            paddingLeft: `calc(100% - ${contentWidth})`,
          }">
            <div class="outer-scrollbar" style="overflow: auto; height: 100%; width: 100%">
              <!-- <div> -->
              <slot />
              <!-- </div> -->
            </div>
          </div>
        </t-content>
      </t-layout>
    </t-layout>
  </t-layout>
</template>
<style></style>
